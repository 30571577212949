.select-dropdown {
  @extend .form-floating;

  width: 100%;
  height: auto;
  text-align: left;
  font-weight: $font-weight-base;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &__label {
    @extend .form-floating__label;
    line-height: $input-line-height;
    opacity: $form-floating-label-opacity;
    transform: $form-floating-label-transform;
    transform-origin: left;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: $form-floating-padding-y $form-floating-padding-x;
  }

  &__button {
    @extend .btn;
    width: 100%;
    height: auto;
    font-weight: $font-weight-base;
    text-align: left;
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
    padding-left: $form-floating-padding-x;
    padding-right: $form-floating-padding-x;
    line-height: normal;
  }

  &__icon {
    color: $text-muted;
    transition: $transition-base;
    margin-top: -0.75rem;
  }

  &__button:hover {
    .select-dropdown__icon {
      color: $primary;
    }
  }

  &__button[aria-expanded='true'] {
    .select-dropdown__icon {
      transform: rotate(180deg);
    }
  }

  &__container {
    &__title {
      @include rfs(11);
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $gray-500;
      letter-spacing: 0.03em;
    }

    &__content {
      padding: 0 calc(#{$form-floating-padding-x} + 2px);
    }

    .link-wrapper {
      position: relative;

      &.active:not(:disabled) {
        &::before {
          content: '';
          background: $dropdown-link-hover-bg;
          left: -$content-card-padding;
          right: -$content-card-padding;
          top: 0;
          bottom: 0;
          position: absolute;
        }
      }
    }
  }
}
