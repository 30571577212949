.loading {
  align-self: center;
  margin: auto;
  text-align: center;

  &--fullscreen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--overlay {
    background: $body-bg;
    z-index: 100000;
  }
}
