.user-picture-stack {
  display: flex;
  align-items: center;
  margin-left: -2px;

  .user-picture-stack__item {
    margin-right: -0.75rem;
    padding: 2px;
    background: white;
    border-radius: 50%;
  }
}
