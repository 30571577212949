// success

#success-circle1 {
  animation: success-circle1_s_do 1500ms linear 1 normal forwards;
}

@keyframes success-circle1_s_do {
  0% {
    stroke-dashoffset: 160;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  66.666667% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#success-path-83 {
  animation: success-path-83_s_do 1500ms linear 1 normal forwards;
}

@keyframes success-path-83_s_do {
  0% {
    stroke-dashoffset: 36;
  }

  33.333333% {
    stroke-dashoffset: 36;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    stroke-dashoffset: 0;
  }
}

// error
#error-circle1 {
  animation: error-circle1_s_do 1500ms linear 1 normal forwards;
}

@keyframes error-circle1_s_do {
  0% {
    stroke-dashoffset: 160;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  66.666667% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#error-line-4_ts {
  animation: error-line-4_ts__ts 1500ms linear 1 normal forwards;
}

@keyframes error-line-4_ts__ts {
  0% {
    transform: translate(26px, 26px) scale(0, 0);
  }

  33.333333% {
    transform: translate(26px, 26px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    transform: translate(26px, 26px) scale(1, 1);
  }
}

#error-line-5_ts {
  animation: error-line-5_ts__ts 1500ms linear 1 normal forwards;
}

@keyframes error-line-5_ts__ts {
  0% {
    transform: translate(26px, 26px) scale(0, 0);
  }

  33.333333% {
    transform: translate(26px, 26px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    transform: translate(26px, 26px) scale(1, 1);
  }
}

// pending
#pending-circle1 {
  animation: pending-circle1_s_do 1500ms linear 1 normal forwards;
}

@keyframes pending-circle1_s_do {
  0% {
    stroke-dashoffset: 160;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  66.666667% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#pending-path-84_tr {
  animation: pending-path-84_tr__tr 1500ms linear 1 normal forwards;
}

@keyframes pending-path-84_tr__tr {
  0% {
    transform: translate(685px, 3360.004px) rotate(0deg);
  }

  42% {
    transform: translate(685px, 3360.004px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    transform: translate(685px, 3360.004px) rotate(360deg);
  }
}

#pending-path-84 {
  animation: pending-path-84_s_o 1500ms linear 1 normal forwards;
}

@keyframes pending-path-84_s_o {
  0% {
    stroke-opacity: 0;
  }

  42% {
    stroke-opacity: 0;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    stroke-opacity: 1;
  }
}

#pending-path-85_tr {
  animation: pending-path-85_tr__tr 1500ms linear 1 normal forwards;
}

@keyframes pending-path-85_tr__tr {
  0% {
    transform: translate(685px, 3360.004px) rotate(0deg);
  }

  23.333333% {
    transform: translate(685px, 3360.004px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  90% {
    transform: translate(685px, 3360.004px) rotate(360deg);
  }

  100% {
    transform: translate(685px, 3360.004px) rotate(360deg);
  }
}

#pending-path-85 {
  animation: pending-path-85_s_o 1500ms linear 1 normal forwards;
}

@keyframes pending-path-85_s_o {
  0% {
    stroke-opacity: 0;
  }

  23.333333% {
    stroke-opacity: 0;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  90% {
    stroke-opacity: 1;
  }

  100% {
    stroke-opacity: 1;
  }
}

// requested
#requested-circle1 {
  animation: requested-circle1_s_do 1500ms linear 1 normal forwards;
}

@keyframes requested-circle1_s_do {
  0% {
    stroke-dashoffset: 160;
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  66.666667% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#requested-subtraction-1_to {
  animation: requested-subtraction-1_to__to 1500ms linear 1 normal forwards;
}

@keyframes requested-subtraction-1_to__to {
  0% {
    transform: translate(14.863717px, 37.688496px);
    animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
  }

  100% {
    transform: translate(24.435398px, 27.656638px);
  }
}

#requested-subtraction-1 {
  animation: requested-subtraction-1_c_o 1500ms linear 1 normal forwards;
}

@keyframes requested-subtraction-1_c_o {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
