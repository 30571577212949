.balance {
  padding: $grid-gutter-width/2;
  background: $gray-200;
  margin: (-$grid-gutter-width) (-$grid-gutter-width/2) $grid-gutter-width
    (-$grid-gutter-width/2);

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    @extend .h5;
    margin: 0;
  }

  &__amount {
    @extend .h5;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width;
    border-radius: $border-radius;
    margin: 0;
    margin-bottom: $grid-gutter-width;

    &__amount {
      @include rfs(42);
      margin: 0;
      color: $headings-color;
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;
    margin-bottom: 0;
  }

  // p-6 shadow-lg rounded mb-6
}
