@import '~react-toastify/scss/main';

// entrance and exit animations
// @import "~react-toastify/scss/animations/bounce";
// @import '~react-toastify/scss/animations/_zoom.scss';
// @import '~react-toastify/scss/animations/_flip.scss';
// @import "~react-toastify/scss/animations/slide";

// see variables
.#{$rt-namespace}__toast {
  border-radius: 0;
  box-shadow: $box-shadow-lg;
  text-align: center;
  font-size: $font-size-sm;

  @include media-breakpoint-up(sm) {
    border-radius: $border-radius;
  }
}

.#{$rt-namespace}__close-button {
  display: none;
}

@keyframes #{$rt-namespace}__bounceInRight {
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutRight {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutLeft {
  0% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes #{$rt-namespace}__bounceOutUp {
  0% {
    transform: translate3d(0, -10px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes #{$rt-namespace}__bounceInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes #{$rt-namespace}__bounceOutDown {
  0% {
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.#{$rt-namespace}__bounce-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__bounceInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__bounceInRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__bounceInDown;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__bounceInUp;
  }
}

.#{$rt-namespace}__bounce-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__bounceOutLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__bounceOutRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__bounceOutUp;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__bounceOutDown;
  }
}
