.badge {
  background: theme-color('danger');
  color: $white;
  border-radius: 50%;
  min-width: 1.125rem;
  height: 1.125rem;
  padding: 0.1rem;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 10;

  &--absolute {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 0 0 0 2px $primary-dark;
  }

  &--text {
    border-radius: $border-radius;
    display: inline-block;
    padding: 0.1rem 0.315rem;
    vertical-align: middle;
    height: auto;
  }

  .dropdown-item & {
    font-size: 0.815rem;
    min-width: 1.5rem;
    height: 1.5rem;
  }
}
