.form-group {
  position: relative;
  padding: 0;
  line-height: 1;
  margin-top: $form-control-custom-label-height/2;

  label {
    font-size: $font-size-sm;
  }

  & > label,
  &__label {
    font-size: $font-size-xs;
    margin: 0;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: $input-padding-x;
    padding: 0 5px;
    line-height: 1;
    background: $body-bg;
    height: $form-control-custom-label-height;
    line-height: $form-control-custom-label-height;
    z-index: 10;
  }

  &:focus-within > label,
  &:focus-within > .form-group__label {
    color: $input-focus-border-color;
  }

  & > .form-control {
    outline: none !important;
    box-shadow: none !important;

    &:disabled,
    &.is-disabled {
      color: $gray-500;
    }

    // &.is-invalid:focus {
    //   border-color: $input-focus-border-color !important;
    // }
  }
  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);

    background-color: $white;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi4wMDAwMDAsIDEyLjAwMDAwMCkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMi4wMDAwMDAsIC0xMi4wMDAwMDApICI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAgMCAyNCAwIDI0IDI0IDAgMjQiPjwvcG9seWdvbj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIwLjUiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgcG9pbnRzPSIxMy4xNzIgMTIgOC4yMjIgNy4wNSA5LjYzNiA1LjYzNiAxNiAxMiA5LjYzNiAxOC4zNjQgOC4yMjIgMTYuOTUiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 22px 22px;
    -webkit-appearance: none;
    appearance: none;
  }
}

.custom-control-label {
  font-size: $font-size-sm;
  line-height: 1.5rem;
}

.custom-switch {
  .custom-control-label {
    font-size: $font-size-base;
  }
}

.hidden-file-input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.input-group {
  flex-wrap: nowrap;
}

.input-group-text {
  line-height: $form-floating-line-height;
}

.input-group {
  &:focus-within {
    .input-group-text {
      border-color: $input-focus-border-color;
    }
  }

  .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-text:not(:first-child) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > .form-control:not(:first-child),
  & > .custom-select:not(:first-child) {
    margin-left: -1px;
  }

  & > .form-floating:not(:first-child) .form-control,
  & > .form-floating:not(:first-child) .custom-select {
    margin-left: -1px;
    border-radius: 0 $input-border-radius $input-border-radius 0;
  }
}
