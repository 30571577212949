h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.01em;
}

.title-caps {
  @include rfs(11);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $gray-500;
  letter-spacing: 0.03em;
}

.hover-primary {
  &:hover,
  a:hover,
  a:hover & {
    color: theme-color('primary');
  }
}

.display-2 {
  font-size: 3.2rem;
}
