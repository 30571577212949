.checkbox-private {
  input[type='checkbox'],
  input[type='radio'] {
    @extend .custom-control-input;
  }

  label {
    display: flex;
    align-items: center;
  }
}
