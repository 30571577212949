$height-full-calc: calc(var(--vh, 1vh) * 100);

$header-height: 4.5rem; // used on modals only
$header-height-desktop: 4.5rem; // used on modals only
$navigation-desktop-width: 20rem;
$navigation-mobile-height: 5rem;

$thumb-size: 4rem;
$thumb-size-xs: 2rem;
$thumb-size-sm: 3rem;
$thumb-size-lg: 5rem;
$thumb-size-xl: 10rem;

$thumb-border-radius-sm: 0.25rem;
$thumb-border-radius: 100rem;

$icon-size: 1.5rem;

$user-menu-width-mobile: 18rem;

$modal-close-button-size: 3.25rem;

$font-size-xs: 0.75rem;

$form-control-custom-label-height: 1rem;

// toastify
$rt-namespace: 'Toastify';
$rt-toast-width: 320px !default;
$rt-toast-background: $white !default;
$rt-toast-min-height: 48px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: $white !default;
$rt-color-dark: theme-color('dark') !default;
$rt-color-info: theme-color('info') !default;
$rt-color-success: theme-color('secondary') !default;
$rt-color-warning: theme-color('warning') !default;
$rt-color-error: theme-color('danger') !default;

$rt-text-color-default: theme-color('dark') !default;
$rt-text-color-dark: $white !default;

$rt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: 'only screen and (max-width : 480px)' !default;
$rt-font-family: inherit !default;
$rt-z-index: 9999 !default;

// react date picker
$datepicker__background-color: $gray-200 !default;
$datepicker__border-color: $gray-200 !default;
$datepicker__highlighted-color: theme-color('success') !default;
$datepicker__muted-color: $text-muted !default;
$datepicker__selected-color: theme-color('primary') !default;
$datepicker__text-color: $body-color !default;
$datepicker__header-color: $headings-color !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: $border-radius !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: $font-size-sm !default;
$datepicker__font-family: inherit !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

// floating form label (should be removed if installed bootstrap 5)
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

// Content Cards
$content-card-padding: 1rem;
$content-card-border-radius: 1rem;
$content-card-item-gap: $grid-gutter-width * 0.75;
$content-card-margin-top: 1rem;

//
$page-container-max-width: 40rem;
$page-container-max-width-sm: 30rem;
