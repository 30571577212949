.user-picture-edit {
  position: relative;
  width: $thumb-size-xl;
  margin: 0 auto;
  margin-bottom: $grid-gutter-width;

  &__edit {
    label,
    .btn {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      cursor: pointer;
      margin: 0;
      transition: $transition-base;
      width: 100%;
      height: 100%;

      &:hover {
        color: theme-color('primary');
      }
    }

    label > span,
    .btn > span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      box-shadow: $box-shadow-lg;
      padding: 0.5rem;
      border-radius: 50%;
      margin: 0.5rem;
    }
  }

  &:hover {
    .user-picture {
      filter: brightness(90%);
    }
  }

  .user-picture {
    width: $thumb-size-xl;
    height: $thumb-size-xl;
    transition: $transition-base;
  }

  &__picture {
    .user-picture {
      width: $thumb-size-xl;
      height: $thumb-size-xl;
      transition: $transition-base;
    }
  }
}
