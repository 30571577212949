.page-content {
  flex-grow: 1;
  &__inner {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }

  .page-header ~ & {
    .page-content__inner {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-grow: 0;
    &__inner {
      padding-bottom: 1rem;
      padding-top: 1.25rem;
    }
  }
}
