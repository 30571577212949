.fullscreen-datepicker .react-datepicker {
  border: 0;
  display: block;
  position: relative;
}

.fullscreen-datepicker .react-datepicker__month-container {
  float: none;
  width: 100%;
}

.fullscreen-datepicker .react-datepicker__day-name,
.fullscreen-datepicker .react-datepicker__day,
.fullscreen-datepicker .react-datepicker__time-name {
  width: 14.28%;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}

.fullscreen-datepicker .react-datepicker__month {
  padding: 0;
  margin: 0;
}
