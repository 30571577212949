.dropdown {
  .dropdown-menu {
    &--scroll {
      max-height: 60vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    &--lg {
      min-width: 20rem;
    }
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1.5rem;

  .icon {
    margin-right: $dropdown-item-padding-x/2;
  }
}
