.notification-message {
  display: flex;

  padding: $dropdown-item-padding-y $grid-gutter-width/2;
  margin: 0 (-$grid-gutter-width/2);
  border-radius: $border-radius;

  &:hover {
    background: $gray-100;
  }

  @include media-breakpoint-up(sm) {
    padding: $dropdown-item-padding-y 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }

  .dropdown-menu & {
    margin-bottom: 0;
    border-radius: 0;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:hover {
      background: $gray-100;
    }
  }
}
