.verification-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.verification-input-item {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 3.2rem;
  }

  height: $input-height;
  padding: 0;
  border-radius: $input-border-radius;
  font-size: $input-font-size;
  text-align: center;
  border: 0;
  color: $input-color;
  background-color: $input-bg;
  box-shadow: 0 0 0 1px #ccc;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  transition: box-shadow 0.2s ease-out;
}

.verification-input-group {
  position: relative;
}

.verification-input-item:focus,
.verification-input-item.current {
  box-shadow: 0 0 0 2px $primary;
  &:after {
    content: '';
    width: 1px;
    height: 20px;
    background: #ec7fff;
    display: inline-block;
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.verification-input-item-label {
  position: absolute;
  top: 0;
  left: 0;
  height: $input-height;
  line-height: $input-height;
  text-align: center;
  width: 100%;
  margin: 0;
  &.current {
    &:after {
      content: '|';
      animation: cursor-blink 1s steps(2) infinite;
    }
  }
}

// the main input field is hidden and the others are used to display the code
.verification-input-hidden-main {
  width: 0px;
  height: 0px;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.verification-code-resend {
  &:hover,
  &:focus {
    color: $primary;
  }

  span {
    font-size: 0.6875rem !important;
  }
}
