// html,
// body {
//   // TODO: should we have those?
//   // overscroll-behavior-y: none;
// }

.layout {
  &--center {
    .content__wrapper {
      justify-content: center;
    }
  }

  &--end {
    .content__wrapper {
      justify-content: flex-end;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;

    .content {
      flex-grow: 1;
    }
  }

  @include media-breakpoint-down(lg) {
    .content__wrapper {
      padding-bottom: $navigation-mobile-height;
    }
  }

  .navigation.is-guest ~ .content {
    .content__wrapper {
      padding-bottom: 0;
    }
  }
}
