.homepage-block {
  display: flex;

  &__icon {
    margin-right: 1.5rem;
    flex-shrink: 0;

    svg {
      fill: currentColor;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__content {
    font-size: $font-size-sm;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
