.btn {
  vertical-align: unset;

  * {
    pointer-events: none;
  }

  &-icon {
    padding: $btn-padding-y;
    height: auto;
    width: auto;
    color: inherit;
    flex-shrink: 0;
    min-width: 1.4rem;
    min-height: 1.4rem;
    align-items: center;
    justify-content: center;
    display: inline-flex;

    &.btn-sm {
      padding: $btn-padding-y-sm;
    }

    &.btn-lg {
      padding: $btn-padding-y-lg;
    }

    .page-header & {
      @include media-breakpoint-up(sm) {
        padding: 0 !important;
      }
    }

    .page-header & + & {
      @include media-breakpoint-up(sm) {
        margin-left: 1.5rem;
      }
    }
  }

  &-text {
    padding: 0;
    height: auto;
    width: auto;
    color: inherit;

    &.active {
      color: theme-color('primary');
      fill: theme-color('primary');
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        fill: theme-color('primary');
      }
    }
  }

  &-outline-secondary {
    color: theme-color('dark');
    border: 1px solid;
  }

  &-outline-light {
    color: theme-color('dark');
    box-shadow: inset 0px 0px 0px 1px theme-color('dark');

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &-min-width {
    min-width: 12rem;
  }

  // Loading
  &.is-loading {
    pointer-events: none;
    color: transparent;
    position: relative;

    .icon,
    svg {
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
      width: 1rem;
      height: 1rem;
      border: 2px solid $white;
      border-right-color: transparent;
      border-top-color: transparent;
      border-radius: 10rem;
      animation: spinner-border #{$spinner-border-speed} linear infinite;
    }

    &.btn-light::before,
    &.btn-secondary::before {
      border-color: theme-color('dark');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-primary::before {
      border-color: theme-color('primary');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-dark::before {
      border-color: theme-color('dark');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-light::before {
      border-color: theme-color('dark');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-secondary::before {
      border-color: theme-color('dark');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-danger::before {
      border-color: theme-color('danger');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-info::before {
      border-color: theme-color('info');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-success::before {
      border-color: theme-color('success');
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &.btn-outline-warning::before {
      border-color: theme-color('warning');
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  // Link on desktop, primary on mobile
  &.primary-mobile {
    @extend .btn-primary;

    @include media-breakpoint-up(sm) {
      background: transparent;
      color: $primary;
      border: none;

      &:hover,
      &:focus,
      &:active {
        background: transparent !important;
        color: $primary !important;
        text-decoration: underline !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &-icon,
    &-link {
      &:hover,
      &:focus,
      &:active {
        color: inherit !important;
        text-decoration: none !important;
      }
    }
  }
}

// active color on primary button
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: theme-color('primary-dark');
}

// list of button blocks spacing
.btn-block + .btn-block {
  margin-top: 1rem !important;
}
