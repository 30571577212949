.filters {
  font-size: $font-size-sm;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    width: auto;
  }

  &__cta {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}
