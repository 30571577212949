$custom-control-indicator-size: 2rem;

$custom-switch-indicator-size: subtract(
  $custom-control-indicator-size,
  $custom-control-indicator-border-width * 4
);

$custom-switch-indicator-border-radius: $custom-control-indicator-size * 0.5;
$custom-switch-width: $custom-control-indicator-size * 1.75;

$custom-control-indicator-bg: $gray-200;

.custom-switch .custom-control-label {
  color: $custom-control-label-color;

  &::before {
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) /
      2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid
      $custom-control-indicator-border-width;
  }

  // Foreground (icon)
  &::after {
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) /
      2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    height: $custom-control-indicator-size;
    content: '';
    box-shadow: $box-shadow-sm;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: $white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23008257' width='24' height='24'%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'/%3E%3C/svg%3E")
    no-repeat center center !important;
}

.custom-switch {
  padding-left: $custom-switch-width + $custom-control-gutter;

  .custom-control-label {
    &::before {
      left: -($custom-switch-width + $custom-control-gutter);
      width: $custom-switch-width;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
    }

    &::after {
      top: add(
        ($font-size-base * $line-height-base - $custom-control-indicator-size) /
          2,
        $custom-control-indicator-border-width * 2
      );
      left: add(
        -($custom-switch-width + $custom-control-gutter),
        $custom-control-indicator-border-width * 2
      );
      width: $custom-switch-indicator-size;
      height: $custom-switch-indicator-size;
      background-color: $custom-control-indicator-border-color;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: $custom-switch-indicator-border-radius;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX(
        $custom-switch-width - $custom-control-indicator-size
      );
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}
