.alert {
  font-size: $font-size-sm;
}

.alert-dismissible .close {
  outline: none;
  padding: 0.5rem 0.75rem;
}

div.fas.fa-fw.fa-exclamation-circle.mr-3.align-self-center:before {
  content: none;
}
