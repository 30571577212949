.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;
  height: $navigation-mobile-height;

  @include media-breakpoint-up(lg) {
    height: 100vh;
    height: fill-available;
    height: -webkit-fill-available;
    display: flex;
    top: 0;
    right: unset;
    bottom: 0;
    position: fixed;
    width: $navigation-desktop-width;
  }

  .btn-text {
    padding: 0;
    margin: 0;
    height: auto;
    line-height: inherit;
    font-weight: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.4rem 1rem 2.5rem;
    border-radius: 0;
    width: 100%;
    text-align: left;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 0.75rem 1rem;
      gap: 1rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem 2.5rem;

    .h1,
    .h2,
    .h3 {
      color: inherit;
    }

    .btn-text {
      padding: 0;
      margin: 0;

      &:hover {
        background-color: transparent !important;
      }

      .is-active {
        color: $primary;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 1.375rem 1rem 2rem 1rem;
      color: $white;
    }
  }

  &__logo {
    display: block;
    height: auto;
    fill: $gray-600;
    width: 1.5rem;

    @include media-breakpoint-up(lg) {
      width: initial;
    }

    &__icon {
      @include media-breakpoint-up(lg) {
        fill: $secondary;
      }
    }

    &__text {
      @include media-breakpoint-up(lg) {
        fill: $white;
      }
    }

    &--active {
      .navigation__logo__icon {
        fill: $primary;

        @include media-breakpoint-up(lg) {
          fill: $secondary;
        }
      }
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &__menu {
    display: flex;
    z-index: $zindex-fixed;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border-top: 1px solid $border-color;

    .btn-text {
      color: $gray-600;
      fill: $gray-600;

      @include media-breakpoint-up(lg) {
        color: $white;
        fill: $white;
      }

      &:hover {
        color: $white;

        @include media-breakpoint-down(md) {
          color: $gray-900;
          background-color: $gray-100;
          .icon {
            color: $gray-900;
          }
        }

        @include media-breakpoint-up(xl) {
          background: rgba(0, 0, 0, 0.2);
          color: $white;
          fill: $white;
          .icon {
            color: $secondary;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      border: 0;
      background: $primary-dark;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: $navigation-desktop-width;
      padding-bottom: 1rem;

      &.is-submenu-open {
        width: 4rem;

        .navigation__item__label {
          display: none;
        }
      }
    }
  }

  &__item {
    position: relative;

    @include media-breakpoint-up(lg) {
      color: $white;
    }

    &__label {
      font-size: 0.6875rem !important;

      @include media-breakpoint-up(lg) {
        display: inline;
        font-size: 1rem !important;
        font-weight: $font-weight-bold;
        line-height: 1.2;
      }
    }

    &__icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__badge {
      position: absolute;
      left: 3.1rem;
      top: 0.3rem;

      @include media-breakpoint-up(lg) {
        left: 2.55rem;
        top: 0.8rem;
      }

      &__dot {
        background-color: $danger;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
      }
    }

    &__badge_bigger {
      position: absolute;
      right: 1.2rem;
      top: 1rem;

      &__dot {
        background-color: $danger;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: block;
      }
    }

    &:hover {
      @include media-breakpoint-up(xl) {
        background: rgba(0, 0, 0, 0.2);
      }

      .btn-text {
        @include media-breakpoint-down(md) {
          color: $gray-900;
        }

        @include media-breakpoint-up(xl) {
          color: $white;
          fill: $white;
        }
      }
    }

    &.is-active {
      .btn-text {
        color: $primary !important;
        background: $white;

        @include media-breakpoint-down(md) {
          &:hover {
            background-color: $gray-100;
          }
        }

        .icon {
          color: $primary !important;
        }
      }
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: $zindex-fixed - 20;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__submenu {
    display: none;
    background: $white;

    div:empty {
      display: none;
    }

    .btn-text {
      flex-direction: row;

      @include media-breakpoint-up(xl) {
        &:hover {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 1.5rem 1.5rem calc(#{$navigation-mobile-height} + 1rem) 1.5rem;

      &.is-active-mobile {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $zindex-fixed - 10;
        border-radius: 1rem 1rem 0 0;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 100vh;
      flex-grow: 1;
      box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.1);
      &.is-active-desktop {
        display: block;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        padding: 1rem 1.5rem;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 -1rem;
      position: relative;

      @include media-breakpoint-up(lg) {
        padding: 1rem 1.5rem;
        gap: 1rem;
      }
    }
  }

  &__submenu-item {
    display: flex;
    padding: 0.5rem 1rem;

    @include media-breakpoint-down(md) {
      &:not(:last-of-type) {
        border-bottom: 1px solid $gray-75;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 0rem 1rem;
      flex: 0 0 auto;
    }

    .active {
      color: $primary !important;
      fill: $primary !important;
    }

    .btn-text {
      padding: 0.5rem 0;
      flex-wrap: wrap;
      gap: 1rem;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }

      &:hover {
        color: inherit;
        fill: inherit;

        @include media-breakpoint-up(xl) {
          color: $primary;
          fill: $primary;
        }
      }
    }

    &__label {
      flex: 0 0 100%;
      display: inline-flex;
      gap: 1rem;
      align-items: flex-start;

      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-bold;
      }
    }

    &__badge {
      background-color: $danger;
      color: $white;
      padding: 0 0.5rem;
      margin-top: 0.1em;
      font-size: 0.875em;
      display: inline-block;
      border-radius: $border-radius;
    }

    &__description {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        flex: 0 0 100%;
        color: $text-muted;
        font-size: 0.875rem;
        line-height: normal;
      }
    }
  }

  &__submenu-component {
    flex: 0 0 100%;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    background: $white;
    position: relative;
    z-index: 10;
  }

  &__share {
    display: flex;
    align-items: center;
    background: $gray-200;
    border: $input-border-color solid $input-border-width;
    border-radius: $border-radius;

    &__input {
      flex-grow: 1;
    }

    .form-control {
      border: none;
      border-right: 1px solid $input-border-color;
      background-color: transparent;
      border-radius: $border-radius 0 0 $border-radius;
    }
  }

  // guest
  &.is-guest {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: unset;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background: $white;
    min-height: $header-height;

    .btn-text {
      padding: 0.75rem 1rem;
      gap: 1rem;
    }

    .navigation__logo {
      fill: unset;
      width: initial;

      &__icon {
        fill: $secondary;
      }

      &__text {
        fill: $primary-dark;
      }
    }
  }
}

// spacer for desktop to control the width
.navigation-spacer {
  height: 0;
  pointer-events: none;
  width: $navigation-desktop-width;
  flex: 0 0 $navigation-desktop-width;
}

// submenu mobile transition
.submenu-transition-enter {
  transform: translateY(100%);
}
.submenu-transition-enter-active {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}
.submenu-transition-exit {
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}
.submenu-transition-exit-active {
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

// backdrop-transition
.backdrop-transition-enter {
  opacity: 0;
}
.backdrop-transition-enter-active {
  opacity: 1;
  transition: opacity 0.2s;
}
.backdrop-transition-exit {
  opacity: 1;
}
.backdrop-transition-exit-active {
  opacity: 0;
  transition: opacity 0.2s;
}
