.select-dropdown-modal {
  @extend .form-floating;

  width: 100%;
  height: auto;
  text-align: left;
  font-weight: $font-weight-base;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &__label {
    @extend .form-floating__label;
    line-height: $input-line-height;
    opacity: $form-floating-label-opacity;
    transform: $form-floating-label-transform;
    transform-origin: left;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: $form-floating-padding-y $form-floating-padding-x;
  }

  &__button {
    @extend .btn;
    width: 100%;
    height: auto;
    font-weight: $font-weight-base;
    text-align: left;
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
    padding-left: $form-floating-padding-x;
    padding-right: $form-floating-padding-x;
    line-height: normal;
  }

  &__icon {
    color: $text-muted;
    transition: $transition-base;
    margin-top: -0.75rem;

    @include media-breakpoint-up(sm) {
      transform: rotate(90deg);
    }
  }

  &__button:hover {
    .select-dropdown-modal__icon {
      color: $primary;
    }
  }

  &__button[aria-expanded='true'] {
    .select-dropdown-modal__icon {
      transform: rotate(90deg);
    }
  }

  &__modal {
    @include media-breakpoint-up(sm) {
      &__close {
        display: none;
      }

      &__title {
        @include rfs(11);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $gray-500;
        letter-spacing: 0.03em;
      }

      &__header {
        display: none;
      }

      &__content {
        padding: 0 calc(#{$form-floating-padding-x} + 2px);
      }
    }

    &__header {
      display: none;
    }

    .link-wrapper {
      position: relative;

      &.active:not(:disabled) {
        background: $dropdown-link-hover-bg;
        padding: 10px;
        border-radius: 5px;
      }
    }

    @include media-breakpoint-down(xs) {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: $zindex-modal;
      background: $modal-content-bg;
      height: auto !important;
      display: flex;
      flex-direction: column;
      min-height: 0;
      transition: none;

      &__header {
        display: flex;
        align-items: center;
        height: $header-height;
      }

      &__content {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        padding: 0 $modal-inner-padding $modal-inner-padding
          $modal-inner-padding;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .content-card + .content-card {
          margin-top: $content-card-padding;
        }
      }

      &__title {
        margin: 0;
      }

      &__close {
        display: block;
      }
    }
  }
}
