.flinksconnect-iframe {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 900px;

  @include media-breakpoint-up(sm) {
    min-height: 60vh;
  }
}
