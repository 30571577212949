.find-people-card {
  display: flex;
  align-items: center;
  margin-bottom: $grid-gutter-width/2;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;

  &__cta-details {
    position: relative;
    z-index: 10;
    color: $gray-600;
    margin-right: -$spacer;

    &:hover {
      color: $link-color;
    }
  }
}
