$transition_main: transform var(--transition-duration)
  cubic-bezier(0.64, 0.11, 0.36, 1);

$transform_main_center: translate3d(
  var(--translate-center-x),
  var(--translate-center-y),
  0
);

$img_scale__default: scale(1);
$img_scale_begin: scale(5);
$img_scale_end: scale(10);

$transition_img: transform var(--transition-duration)
  cubic-bezier(0.5, -2, 0.5, 2.5);

.emotion-animation {
  backface-visibility: hidden;

  &__img {
    backface-visibility: hidden;
  }
}

.emotion-animation-enter {
  opacity: 1 !important;
  transform: $transform_main_center;

  .emotion-animation__img {
    opacity: 0;
    transform: $img_scale_begin;
  }
}

.emotion-animation-enter-active {
  opacity: 1 !important;
  transition: $transition_main;
  transform: $transform_main_center;

  .emotion-animation__img {
    opacity: 1;
    transition: $transition_img, opacity 0.25s ease;
    transform: $img_scale_end;
  }
}

.emotion-animation-exit {
  opacity: 1 !important;
  transform: $transform_main_center;

  .emotion-animation__img {
    transform: $img_scale_end;
    transition: $transition_main, opacity 0.25s ease;
  }
}
.emotion-animation-exit-active {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0);
  transition: $transition_main;

  .emotion-animation__img {
    transform: $img_scale__default;
    transition: $transition_main, opacity 0.25s ease;
  }
}

.emotion-animation-exit-done {
  opacity: 1 !important;
}
