.link-wrapper {
  @extend .btn;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  width: 100%;
  text-decoration: none;
  display: block;
  text-align: inherit;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
