.close,
.close-arrow {
  @extend .btn, .btn-icon;
  outline: none;
  font-size: 2.5rem;
  font-weight: $font-weight-base;
  line-height: 1rem;
  opacity: 100;
  height: $header-height;
  padding: 0 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    padding: 0 1rem;
  }

  &:hover {
    color: theme-color('primary');
  }
}

// cross
.close {
  display: none;
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    width: 1.4rem;
    height: 1.4rem;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .modal-header &,
  .page-header & {
    padding: 0;
    margin: 0 0 0 1.5rem;
  }
}

// back arrow
.close-arrow {
  margin-right: auto;
  margin-left: -$modal-inner-padding;

  @include media-breakpoint-up(sm) {
    margin-left: -$modal-inner-padding-desktop;
  }

  display: none;

  &--mobile {
    display: flex;

    @include media-breakpoint-up(sm) {
      display: none;

      &.close-arrow--desktop {
        display: flex;
      }
    }
  }

  &--desktop {
    display: none;
    &.close-arrow--mobile {
      display: flex;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }
}
