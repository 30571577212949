.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-shrink: 0;
  flex-grow: 1;

  .container {
    max-width: $page-container-max-width !important;
  }

  &--centered {
    @include media-breakpoint-up(lg) {
      margin: auto 0;
      flex-grow: 0;
      min-height: auto;
    }
  }

  &--sm {
    .container {
      max-width: $page-container-max-width-sm !important;
    }
  }
}
