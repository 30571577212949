.page-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $body-bg;
  padding: $grid-gutter-width * 0.5 0;
  z-index: $zindex-sticky;
  min-height: $header-height;
  transition: all 0.3s ease-in-out;
  flex-direction: column;

  .container {
    z-index: 10;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  &.is-sticky {
    position: sticky;
    top: -1px;

    &.is-scrolled-down {
      transform: translateY(-100%);
    }

    &.is-scrolled-up {
      transform: translateY(0);
    }

    &.is-sticked {
      box-shadow: $box-shadow-sm;
    }
  }

  .container {
    position: relative;
    z-index: 10;
  }

  &__dropdown {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0px;
    right: 0;
    width: 100%;
    max-width: unset !important;
    z-index: 5;
    background-color: $white;
    box-shadow: $box-shadow-sm;

    &__in {
      padding: calc(#{$header-height} + 1rem) 0 2rem 0;
    }
  }
}
