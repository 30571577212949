.text-sm {
  @include rfs(14);
}

.text-xs {
  @include rfs(12);
}

.h-screen {
  height: 100vh;
  height: $height-full-calc;
}

.h-screen-without-header {
  height: calc(100vh - #{$header-height});
  height: calc(#{$height-full-calc} - #{$header-height});

  @include media-breakpoint-up(lg) {
    height: auto;
  }
}

.overflow-auto {
  -webkit-overflow-scrolling: touch;
}

.min-w-0 {
  min-width: 0;
}

.max-w-full {
  max-width: 100%;
}

// add responsive widths ex: .w-sm-50
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

// add responsive top and bottom ex: .top-50
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (top: top, bottom: bottom, left: left, right: right)
    {
      .#{$abbrev}#{$infix}-0 {
        #{$prop}: 0 !important;
      }
    }
  }
}
