$modal-inner-padding-desktop: $modal-inner-padding * 1.3;

.modal {
  // header
  .modal-header {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 $modal-inner-padding;

    @include media-breakpoint-up(sm) {
      padding: 0 $modal-inner-padding-desktop;
    }
  }

  // title
  .modal-title {
    min-height: $header-height;
    display: flex;
    align-items: center;
    width: 100%;
    align-self: center;
  }

  // body
  .modal-body {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    z-index: 10;

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    &.is-overflow {
      &::after {
        content: '';
        position: sticky;
        top: 100%;
        flex-shrink: 0;
        margin-left: -$modal-inner-padding;
        margin-right: -$modal-inner-padding;
        margin-bottom: -$modal-inner-padding;
        height: $modal-inner-padding;

        @include media-breakpoint-up(sm) {
          margin-left: -$modal-inner-padding-desktop;
          margin-right: -$modal-inner-padding-desktop;
          margin-bottom: -$modal-inner-padding-desktop;
          height: $modal-inner-padding-desktop;
        }

        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0.2) 100%
        );
        z-index: 20;
      }
    }
  }

  //footer
  .modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 0;
  }

  // dialog
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      .modal-body {
        padding: $modal-inner-padding-desktop;
        padding-top: $modal-inner-padding;
      }
    }

    // all fullscreen
    &.is-fullscreen-desktop {
      max-height: 100%;
      min-height: 100%;
      height: 100%;
      margin: 0;
      max-width: none;

      .modal-content {
        border-radius: 0;
        height: 100%;
        max-height: none;
      }
    }

    // fullscreen mobile
    &.is-fullscreen-mobile {
      @include media-breakpoint-down(xs) {
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        margin: 0;
        max-width: none;

        .modal-content {
          border-radius: 0;
          height: 100%;
          max-height: none;
        }
      }
    }
  }
}

// fullscreen transitions
.modal.fade .modal-dialog.is-fullscreen-desktop,
.modal.fade .modal-dialog.is-fullscreen-mobile {
  @include media-breakpoint-down(xs) {
    transform: scale(1) translateX(100%) !important;
  }

  .modal-content {
    transition: $transition-base;

    @include media-breakpoint-down(xs) {
      transform: translateX(100%);
    }
  }
}

.modal.show .modal-dialog.is-fullscreen-desktop,
.modal.show .modal-dialog.is-fullscreen-mobile {
  transform: none !important;

  @include media-breakpoint-down(xs) {
    .modal-content {
      transform: none;
    }
  }
}

.modal-backdrop.is-fullscreen-desktop {
  @include media-breakpoint-up(sm) {
    transition: none;
    opacity: 0;
  }
}

// z-indexes if multiple
.modal.show:last-of-type {
  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
}

.modal-top {
  .modal-dialog {
    margin: 0 auto 1.75rem;

    .modal-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.modal-top,
.modal-center {
  .modal-dialog {
    max-width: calc($page-container-max-width - 2rem) !important;
  }

  &.modal.fade .modal-dialog {
    transform: translateY(-50px) !important;
  }

  &.modal.show .modal-dialog {
    transform: none !important;

    .modal-content {
      transform: none;
    }
  }
}

.modal-bottom {
  .modal-dialog-centered {
    min-height: 100%;
  }

  .modal-dialog {
    max-width: calc($page-container-max-width - 2rem) !important;
    margin: 0 auto;

    .modal-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.modal.fade .modal-dialog {
    transform: scale(1) translateY(100%) !important;
    align-items: flex-end !important;

    .modal-content {
      transform: translateY(100%);
    }
  }

  &.modal.show .modal-dialog {
    transform: none !important;

    .modal-content {
      transform: none;
    }
  }
}
