.payment-user-card {
  display: flex;
  align-items: center;
  margin-bottom: $form-group-margin-bottom;
  position: relative;
  user-select: none;

  &__title {
    word-break: break-word;
  }

  &__cta-details {
    position: relative;
    z-index: 10;
    color: $gray-600;

    &:hover {
      color: theme-color('primary');
    }
  }
}
