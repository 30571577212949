.bubble {
  position: relative;
  background: $white;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  border-radius: $border-radius;
  padding: 1rem;
  word-wrap: break-word;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 20px 12px 0;
    border-color: transparent $white transparent transparent;
    bottom: -12px;
    left: 10%;
    margin-left: -10px;
  }
}
