.link {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

.find-people-card:hover .link,
.link-wrapper:hover .link {
  color: $link-hover-color;
}
