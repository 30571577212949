.billsplit-modal {
  @include media-breakpoint-up(sm) {
    height: unset !important;
    max-height: unset !important;

    .dropdown-autocomplete__menu {
      z-index: $zindex-popover;
    }

    .modal-content {
      overflow: visible;
    }

    .modal-body {
      overflow: visible;
    }
  }
}
