.keen-slider-navigation {
  &__arrow {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      width: 1.5rem;
      height: 4rem;
      position: absolute;
      border-radius: 1rem;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      fill: $headings-color;

      background: $gray-50;
      border: 1px solid $gray-300;

      &:hover {
        background: $gray-200;
      }

      cursor: pointer;
      &--left {
        left: -$spacer * 2;
      }

      &--right {
        left: auto;
        right: -$spacer * 2;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &--inside-dropdown {
      @include media-breakpoint-up(md) {
        display: none;
      }
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  }
}
