.page-footer {
  position: sticky;
  bottom: $navigation-mobile-height;
  left: 0;
  padding: 1rem 0;
  background-color: $body-bg;
  z-index: $zindex-dropdown - 5;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;

  &.is-sticked {
    box-shadow: 0 -0.125rem 0.25rem rgba($black, 0.075);
    bottom: -1px !important;
  }

  &:empty {
    display: none;
  }

  // @include media-breakpoint-up(sm) {
  //   z-index: 10;
  // }

  @include media-breakpoint-up(lg) {
    bottom: 0;
  }

  &.is-guest {
    bottom: 0;
  }
}
