.btn-group-toggle {
  .btn-link {
    padding: 0.5rem;
    border-radius: $btn-border-radius !important;
  }

  .btn.active.btn-link {
    background: $gray-50;
    color: inherit;
  }
}
