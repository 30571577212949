.filter-input-holder {
  @extend .form-control;

  display: flex;
  align-items: center;
  width: auto;
  position: relative;
  padding: 0;
  height: auto;
  box-sizing: border-box;
  margin-bottom: $spacer/2;
  font-weight: $font-weight-normal;

  &:focus-within {
    border-color: $input-focus-border-color;
  }

  .form-control {
    border: 0;
    background: transparent;
    width: auto;
    flex: 1 1 auto;
  }

  .btn {
    flex-shrink: 0;
  }
}
