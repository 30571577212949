.dropdown-autocomplete {
  position: relative;
  display: flex;
  flex-direction: column;

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  &__input {
    position: relative;
    z-index: 25;
  }

  &__menu {
    @extend .dropdown-menu;
    display: flex;
    float: none;
    flex-direction: column;
    width: 100%;
    top: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 $spacer;
    z-index: 1000;

    @include media-breakpoint-up(sm) {
      animation: 0.25s ease-in-out 1 alternate slideIn;
    }

    @include media-breakpoint-up(xl) {
      max-height: 35rem !important;
    }
  }

  &__close-bottom {
    text-align: center;
    position: sticky;
    bottom: 0;
    margin: auto (-$spacer) 0 (-$spacer);
    flex-shrink: 0;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(255, 255, 255, 0),
    //   rgba(255, 255, 255, 1) 50%
    // );

    .btn {
      background: $gray-100;
      border: 1px solid $gray-300;
      border-bottom: 0;
      border-radius: 50% 50% 0 0;
      padding: 0.315rem;

      &:hover {
        background: $gray-200;
      }
    }
  }

  &__search-tip {
    padding: $spacer * 2 0;
    text-align: center;
    font-size: $font-size-sm;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    flex-shrink: 0;

    .icon {
      margin-left: $spacer;
      margin-right: -($spacer + $icon-size);
      fill: $gray-500;
    }
  }

  &--is-onpage {
    .dropdown-autocomplete__menu {
      position: static;
      box-shadow: none;
      background: transparent;
      border-radius: 0;
      padding: 0;
      height: auto !important;
      min-height: 0 !important;
      max-height: none !important;
      overflow: unset;
      animation: none;
    }

    .dropdown-autocomplete__header {
      position: sticky;
      top: $header-height;
      z-index: 30;
      padding: $spacer;
      padding-bottom: 0;
      margin: -$spacer;
      margin-bottom: 0;
      background: $body-bg;
    }
  }

  // on mobile like modal
  &.is-active {
    @include media-breakpoint-down(xs) {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: $zindex-modal;
      background: $dropdown-bg;

      .dropdown-autocomplete__backdrop {
        display: none;
      }

      .dropdown-autocomplete__header {
        display: flex;
        align-items: center;
        padding: 0 $modal-inner-padding/2 0 $modal-inner-padding;
        height: $header-height;
      }

      .dropdown-autocomplete__menu {
        position: relative;
        box-shadow: none;
        background: transparent;
        border-radius: 0;
        height: auto !important;
        min-height: 0 !important;
        max-height: none !important;
        overflow: unset;
        z-index: $zindex-modal;
        top: 0;
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 $modal-inner-padding $modal-inner-padding
          $modal-inner-padding;
      }

      .dropdown-autocomplete__close-bottom {
        display: none;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: transform;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
