.form-helper {
  position: absolute;
  padding: $grid-gutter-width/2;
  background: map-get($colors, 'color-info-100');
  border-radius: $border-radius;
  font-size: $font-size-xs;
  line-height: 1.2;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 1px;
  z-index: 20;

  * {
    font-size: $font-size-xs !important;
  }

  a {
    font-weight: $font-weight-bold;
    color: map-get($colors, 'color-info-700');
  }
}
