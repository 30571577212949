.transaction-pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-weight: $font-weight-normal;
  height: 100%;
  transition: $transition-base;

  a:hover > &,
  &:hover {
    background: $white;
  }

  &__user {
    color: $headings-color;
    word-break: break-word;
  }

  &__amount {
    white-space: nowrap;
  }
}
