.transaction {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-bottom: $grid-gutter-width * 0.75;
  font-weight: $font-weight-normal;

  &__user {
    color: $headings-color;
    word-break: break-word;
  }

  &__amount {
    color: $headings-color;
    white-space: nowrap;
  }
}

.countdown-timer {
  display: block;
  line-height: 0.85;
  font-size: 0.7rem;
  font-weight: 100;
}
