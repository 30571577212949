.dropdown-emotions {
  line-height: 1;
  &__btn {
    @extend .btn;
    @extend .btn-icon;

    &::after {
      content: none;
    }

    &:hover {
      color: $primary;
    }
  }

  .dropdown-menu {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .dropdown-item {
    border-radius: $btn-border-radius;
  }
}
