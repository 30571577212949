.content-card {
  background: $white;
  padding: $content-card-padding !important;
  border: 0 !important;
  border-radius: $content-card-border-radius !important;

  & + & {
    margin-bottom: 0 !important;
    margin-top: $content-card-margin-top !important;
  }

  &:empty {
    display: none;
  }

  & > .link-wrapper {
    margin-bottom: 0 !important;
  }

  .link-wrapper + .link-wrapper {
    margin-top: $content-card-item-gap !important;
  }

  &__item {
    display: flex;

    .transaction {
      margin: 0;
    }

    & + .content-card__item {
      margin-top: $content-card-item-gap;
      scroll-margin-top: 80px;
    }
  }

  &__w_menu {
    padding-right: calc($content-card-padding/2) !important;
  }
}
