.content {
  height: 100vh;
  height: $height-full-calc;

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 0 0 $grid-gutter-width 0;

    // @include media-breakpoint-up(sm) {
    //   padding: $grid-gutter-width 0 $grid-gutter-width * 2 0;
    // }

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width * 1.5 0 $grid-gutter-width * 2;
    }
  }

  &--blank {
    padding: 0 !important;
  }

  .navigation.is-guest + & {
    padding-top: $header-height;

    @include media-breakpoint-up(lg) {
      padding-top: $header-height-desktop;
    }
  }
}
