.event:not(:last-child) {
  margin-bottom: 1rem;
}

.positive-event {
  border-left: 0.3rem solid $secondary;
}

.negative-event {
  border-left: 0.3rem solid $danger-light;
}
