@import './config/bootstrap-import';
@import './config/variables';
@import './components/toastify';
@import './components/type';
@import './components/forms';
@import './components/eft-timeline';
@import './components/form-control-payment';
@import './components/alert';
@import './components/container';
@import './components/layout';
@import './components/content-card';
@import './components/content';
@import './components/icon';
@import './components/btn';
@import './components/dropdown';
@import './components/modal';
@import './components/spinner';
@import './components/loading';
@import './components/user-picture';
@import './components/badge';
@import './components/sticky-top-content';
@import './components/navigation';
@import './components/notification-message';
@import './components/transaction';
@import './components/transaction-pending';
@import './components/balance';
@import './components/find-people-card';
@import './components/filters';
@import './components/link-wrapper';
@import './components/link';
@import './components/payment-user-card';
@import './components/checkbox-private';
@import './components/form-helper';
@import './components/user-picture-edit';
@import './components/payment-method-card';
@import './components/status-animated';
@import './components/react-datepicker';
@import './components/btn-group-toggle';
@import './components/filter-input-holder';
@import './components/dropdown-autocomplete';
@import './components/keen-slider-navigation';
@import './components/close';
@import './components/select-dropdown-modal';
@import './components/select-dropdown';
@import './components/flinksconnect-iframe';
@import './components/close-button-over-iframe';
@import './components/user-picture-stack';
@import './components/billsplit-modal';
@import './components/modal-share-button';
@import './components/homepage-block';
@import './components/custom-switch';
@import './components/emotion-animation';
@import './components/dropdown-emotions';
@import './components/bubble';
@import './components/form-floating';
@import './components/page-container';
@import './components/page-header';
@import './components/page-footer';
@import './components/page-content';
@import './components/verification-input';
@import './components/radio';
@import './components/iframe';
@import './components/datepicker';

@import './components/utils';

.item-enter-done {
  // position: relative;
  // background-color: red;
}

.item-enter {
  opacity: 0;
  transform: translateY(10px);
}
.item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.5s ease-in-out;
}
