.close-button-over-iframe {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.6rem;
  height: auto;
  margin: 0;
  padding: 10px !important;
  margin: 10px !important;
  display: block;
  background-color: $white !important;
}
