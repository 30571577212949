.user-picture {
  position: relative;
  height: 0;
  // background: theme-color("light");
  display: flex;
  align-items: center;
  justify-content: center;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: $thumb-border-radius;
  user-select: none;
  margin: 0;
  font-weight: $font-weight-bold;
  flex-shrink: 0;
  text-transform: uppercase;
  overflow: hidden;

  &__preffered {
    position: absolute;
    right: -0.25rem;
    bottom: -0.25rem;
    background: white;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme-color('secondary');
    z-index: 10;
    border-radius: 50%;
    box-shadow: $box-shadow-sm;
  }

  img:not(.icon) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $thumb-border-radius;
    position: absolute;
    left: 0;
    top: 0;
  }

  .icon {
    width: calc(max(1.5rem, 40%));
    height: auto;
  }

  .icon-square {
    width: calc(max(2.5rem, 85%));
  }

  &--xs {
    width: $thumb-size-xs;
    height: $thumb-size-xs;
    @include rfs(11);

    .icon {
      width: calc(max(1rem, 40%));
      height: auto;
    }
  }

  &--sm {
    width: $thumb-size-sm;
    height: $thumb-size-sm;
  }

  &--lg {
    @include rfs(24);
    width: $thumb-size-lg;
    height: $thumb-size-lg;
  }

  &--xl {
    width: $thumb-size-lg;
    height: $thumb-size-lg;
    @include rfs(32);

    @include media-breakpoint-up(lg) {
      @include rfs(48);
      width: $thumb-size-xl;
      height: $thumb-size-xl;
    }
  }
}
