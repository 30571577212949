.modal-share-button {
  @extend .btn, .btn-icon;
  outline: none;
  margin-left: auto;
  padding: 0 0.5rem;

  &:hover {
    color: theme-color('primary');
  }

  .modal-header &,
  .page-header & {
    @include media-breakpoint-up(sm) {
      padding: 0;
      margin: 0 0 0 1.5rem;
    }
  }
}
