.custom-radio {
  display: flex;
  flex-direction: row;
  align-items: center;

  .custom-control-label::before,
  .custom-control-label::after {
    top: calc(50% - (1rem / 2));
  }

  .custom-control-label {
    padding-left: 0.5rem;
  }
}
