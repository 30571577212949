.icon {
  width: $icon-size;
  height: $icon-size;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;

  &--12 {
    width: 0.75rem;
    height: 0.75rem;
  }

  &--16 {
    width: 1rem;
    height: 1rem;
  }

  &--20 {
    width: 1.3rem;
    height: 1.3rem;
  }

  &--24 {
    width: 1.4rem;
    height: 1.4rem;
  }

  &--32 {
    width: 2rem;
    height: 2rem;
  }

  &--40 {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--48 {
    width: 3rem;
    height: 3rem;
  }

  &--64 {
    width: 4rem;
    height: 4rem;
  }
}
