// floating form label (should be removed if installed bootstrap 5)

.form-floating {
  position: relative;
  width: 100%;

  .form-control,
  .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
    width: 100%;
  }

  // .form-floating__label is added so we can extend inside select-dropdown-modal.scss

  label,
  .form-floating__label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
    line-height: $input-line-height;
    font-size: $input-font-size;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    opacity: $form-floating-label-opacity;
    color: $body-color;
    z-index: 20;
  }

  // stylelint-disable no-duplicate-selectors
  .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;

    &::placeholder {
      color: transparent;
      text-shadow: none !important;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  .form-control:focus,
  .form-control:not(:placeholder-shown),
  .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // stylelint-enable no-duplicate-selectors

  // fix textarea overflow
  &:has(textarea.form-control) {
    &::before {
      content: '';
      position: absolute;
      top: $input-border-width;
      left: $input-border-width;
      right: $input-border-width;
      height: $form-floating-input-padding-t;
      pointer-events: none;
      border-radius: $input-border-radius $input-border-radius 0 0;
      // background-color: $input-bg;
      z-index: 10;
    }
  }

  &.has-suffix {
    .form-control,
    .form-select {
      padding-right: 3.5rem;
    }
  }
  &__suffix {
    position: absolute;
    z-index: 30;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: $form-floating-padding-y $form-floating-padding-x;
  }
}
